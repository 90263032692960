import React from "react";
import {graphql} from "gatsby";
import InternalLayout from "../../layouts/internallayout";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Container = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 4em 1em;
  position: relative;
  overflow: hidden;

  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
`
const Logos = styled.div`
    display: flex;
    flex-direction: column;
  width: 100%;
  float: none;
  
  @media(min-width: 768px){
    flex-direction: row;
    width: 40%;
    float: right;
  }
`

const Logo  = styled(GatsbyImage)`
  width: 100%;

  @media(min-width: 768px){
    width: calc(50% - 2rem);
    margin: 1rem;
  }
`



class OnlineConsultsPage extends React.Component {


    render() {
        return (
            <InternalLayout>
                <Container>
                    <h1>Online Physio Consultants</h1>
                    <Logos>
                        <Logo
                            image={this.props.data.skype.childImageSharp.gatsbyImageData}
                            alt="Skype Logo"
                            objectFit="contain"
                        />
                        <Logo
                            image={this.props.data.zoom.childImageSharp.gatsbyImageData}
                            alt="Zoom Logo"
                            objectFit="contain"
                        />
                    </Logos>
                    <p><strong>
                        Can't get to a Physio in person? <br />
                        Want to speak to a leading Physiotherapist about your problem?<br />
                        Need a personalised rehab program designed just for you?
                    </strong></p>

                    <p>Our clinical expertise is available from the comfort and convenience of your home through video consults. Telehealth online video physiotherapy
                        consultations are ideal for people in remote areas, home isolation, people who are travelling, or ones simply are physically unable to make it into their normal Physio. They
                        are a highly effective alternative to face-2-face in-room consultations as we are able to assess, diagnose and help you with exercises and rehab programs through video.</p><p>Perhaps
                        you just want to get a second opinion about your injury or speak to someone who simply knows their stuff. We can help you with the right exercises, correct your form and technique as
                        well as advise you on what to do next. With an initial consult, you can get an online video assessment and consult about your pain or injury, as well as a personalised rehab program
                        designed specifically just for you, to help you through any recovery. You can also book follow up video consultations as needed.</p><p>Not tech-savvy? Not to worry. We will guide
                        you through our simple to use technology prior to the consultation. The technology we use is encrypted to ensure your privacy.</p><p>What exactly should you expect from a video
                        consult?</p>
                    <p>We offer our same standard of professional service through video consults.</p>
                        <p>During a consultation you will receive:</p>
                        <ol>
                            <li>Detailed and in-depth assessment from a Physiotherapist</li>
                            <li>Diagnosis of your presenting concern.</li>
                            <li>Self-management advice.</li>
                            <li>A personalised exercise rehab program including home exercises and lifestyle
                            modifications to manage pain or injury.
                            </li>
                        </ol>

                        <p>Initial consults are 40 minutes in duration ($70.00) and subsequent consults are 30
                        minutes
                        in duration ($60.00). Medicare and health insurance rebates may apply.</p>
                        <p>Instructions
                        for your video health consultation.</p>

                    <p>The consult may be conducted on your desktop computer or on a smart-phone. This will be your decision according to your personal preference.</p>

                    <p><strong>For a desktop consult:</strong></p>
                        <ol>
                            <li>Open your internet browser and search <a href="https://zoom.us/join">https://zoom.us/join</a> or <a href="https://www.skype.com/en/get-skype/">https://www.skype.com/en/get-skype/</a></li>
                            <li>There will be a prompt to enter a Meeting ID. This will have been emailed to you prior to the scheduled appointment.</li>
                            <li>Follow the prompts on the webpage.</li>
                            <li>This should take you through to the virtual meeting. Please call if you have any difficulties.</li>
                        </ol>

                        <p><strong>For a mobile consult:</strong></p>
                        <ol>
                            <li>Download the zoom or skype app and open the app.</li>
                            <li>Choose the "Join" icon, second from the left at the top.</li>
                            <li>Enter the meeting ID emailed to you prior to your appointment.</li>
                            <li>Click join. The app will prompt you to enable video and audio call via the internet, please select allow or enable.</li>
                            <li>This should take you through to the virtual meeting. Please call if you have any difficulties.</li>
                        </ol>
                </Container>
            </InternalLayout>
        )
    }
}

export default OnlineConsultsPage


export const pageQuery = graphql`
    query {
        zoom: file(relativePath: {eq: "zoom.png"}) {
            childImageSharp {
              gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  placeholder: NONE
              )
            }
          } 
          skype: file(relativePath: {eq: "skype.png"}) {
            childImageSharp {
              gatsbyImageData(
                  formats: [AUTO, WEBP]
                  layout: CONSTRAINED
                  quality: 100
                  placeholder: NONE
              )
            }
          }
    }
`;